import {HomeAggregate} from "@seeair/schemas";
import {HStack, TextBase, TextLg, TextLink, VStack} from './DesignBase.js';
import {Textarea, TextInput} from "@mantine/core";
import {useState} from "react";
import {DesignedButton} from './DesignComponents.js';
import {trpc} from "./trpc.js"
import {getUseMutationOpt} from './mutationHelper.js';
import React from 'react'

export function EditAddressPanel({home}:{home:HomeAggregate}) {
    const [aliases,setAliases] = useState((home.address.address_aliases ?? []))
    const {mutate:saveAddressAliases} = trpc.ADMIN.setAddressAliases.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <HStack leftCenter>
            <TextLg classNames="mr-4">{home.lat},{home.long}</TextLg>
            <a href={`https://earth.google.com/web/@${home.lat},${home.long},1000a,0h,0t,0r`}
               target="_blank" rel="noreferrer" className="mr-4"><TextLink>Earth</TextLink></a>
            <a href={`https://www.google.com/maps/@${home.lat},${home.long},20z?basemap=satellite`}
               target="_blank" rel="noreferrer"><TextLink>Maps</TextLink></a>
        </HStack>

        <TextInput value={home.address.address1} label="address1" disabled/>
        <TextInput value={home.address.address2 ?? ""} label="address2" disabled />
        <TextInput value={home.address.neighborhood ?? ""} label="neighborhood" disabled />
        <TextInput value={home.address.city} label="city" disabled />
        <TextInput value={home.address.county ?? ""} label="county" disabled />
        <TextInput value={home.address.state} label="state" disabled />
        <TextInput value={home.address.countryCode} label="countryCode" disabled />
        <TextInput value={home.address.postalCode} label="postalCode" disabled />
        <TextBase>{home.full_address_id}</TextBase>
        <Textarea
            value={aliases.join('\n')}
            onChange={(event)=>setAliases(event.currentTarget.value.split('\n'))}
            label="Alternate Address Names" />
        <DesignedButton onClick={()=>saveAddressAliases({home_id:home.home_id,addressAliases:aliases})}>Save</DesignedButton>
    </VStack>
}