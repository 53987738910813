import {notifications} from "@mantine/notifications";
import {TRPCClientErrorLike} from "@trpc/client";

export function getUseMutationOpt(utils:{ADMIN:{invalidate:()=>Promise<void>},HOMEOWNER:{invalidate:()=>Promise<void>}},onSuccess?:()=>void,onError?:(error:TRPCClientErrorLike<any>)=>void){
    return {
        onSuccess: async () => {
            await utils.ADMIN.invalidate()
            await utils.HOMEOWNER.invalidate()
            showSuccessNotification()
            if(onSuccess) {
                onSuccess()
            }
        },
        onError: (error:TRPCClientErrorLike<any>) => {
            showErrorNotification(error)
            if(onError) {
                onError(error)
            }
        }
    }
}
export function showSuccessNotification() {
    notifications.show({
        message:"Success!",
        color: "green",
        autoClose: 3000
    })
}
export function showErrorNotification(error:{message:string}) {
    console.log(`showErrorNotification: ${error.message}`)
    notifications.show({
        title: "Failure",
        message: error.message,
        color: 'red',
        autoClose: false
    })
}