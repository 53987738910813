import {integer, timestamp, pgTable, primaryKey, varchar, jsonb} from 'drizzle-orm/pg-core'
import type {AdapterAccount} from '@auth/core/adapters'
import {HubspotEligibilitySurveyData, UserRole} from './jsonb-schemas.js';

export const users = pgTable('user', {
    id: varchar('id', {length: 255}).notNull().primaryKey(),
    created_at: timestamp('created_at').defaultNow().notNull(),
    name: varchar('name', {length: 255}),
    email: varchar('email', {length: 255}).notNull(),
    emailVerified: timestamp('emailVerified', {mode: 'date'}).defaultNow(),
    image: varchar('image', {length: 255}),
    company_id: varchar('company_id', {length: 255}),
    role: varchar('role', {length: 255}).$type<UserRole>(),
})

export const waitlist = pgTable('waitlist',{
    email:varchar('email',{length:255}).notNull().primaryKey(),
    hubspot_contact_id:varchar('hubspot_contact_id',{length:255}).notNull().unique(),
    eligibility_data:jsonb('eligibility_data').$type<HubspotEligibilitySurveyData>()
})

export const accounts = pgTable(
    'account',
    {
        userId: varchar('userId', {length: 255}).notNull(),
        type: varchar('type', {length: 255}).$type<AdapterAccount['type']>().notNull(),
        provider: varchar('provider', {length: 255}).notNull(),
        providerAccountId: varchar('providerAccountId', {length: 255}).notNull(),
        refresh_token: varchar('refresh_token', {length: 255}),
        access_token: varchar('access_token', {length: 255}),
        expires_at: integer('expires_at'),
        token_type: varchar('token_type', {length: 255}),
        scope: varchar('scope', {length: 255}),
        id_token: varchar('id_token', {length: 2048}),
        session_state: varchar('session_state', {length: 255}),
    },
    (account) => ({
        compoundKey: primaryKey({columns: [account.provider, account.providerAccountId]}),
    })
)
/*
export const sessions = pgTable('session', {
	sessionToken: varchar('sessionToken', {length: 255}).notNull().primaryKey(),
	userId: varchar('userId', {length: 255}).notNull(),
	expires: timestamp('expires', {mode: 'date'}).notNull(),
})
 */
export const verificationTokens = pgTable(
    'verificationToken',
    {
        identifier: varchar('identifier', {length: 255}).notNull(),
        token: varchar('token', {length: 255}).notNull(),
        expires: timestamp('expires', {mode: 'date'}).notNull(),
    },
    (vt) => ({
        compoundKey: primaryKey({columns: [vt.identifier, vt.token]}),
    })
)
