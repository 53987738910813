import {COLOR_WHITE, FullWithDesignedSection} from "@seeair/shared-components";
import {Tabs} from "@mantine/core";
import {IconClock, IconSelectAll, IconToolsKitchen} from "@tabler/icons-react";
import {AdminRecentAssessmentsTable} from './AdminRecentAssessmentsTable.js';
import {AdminAllAssessmentsTable} from './AdminAllAssessmentsTable.js';
import {AdminUserManager} from './AdminUserManager.js';
import React from "react";
import {AdminToolsPane} from './AdminToolsPane.js';

export function AdminDashboard() {
    return <FullWithDesignedSection background={COLOR_WHITE}>
        <Tabs defaultValue="recent" keepMounted={false}>
            <Tabs.List>
                <Tabs.Tab value="recent" leftSection={<IconClock/>}>Recent</Tabs.Tab>
                <Tabs.Tab value="all" leftSection={<IconSelectAll/>}>All</Tabs.Tab>
                <Tabs.Tab value="users" leftSection={<IconSelectAll/>}>Users</Tabs.Tab>
                <Tabs.Tab value="tools" leftSection={<IconToolsKitchen/>}>Tools</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="recent">
                <AdminRecentAssessmentsTable />
            </Tabs.Panel>
            <Tabs.Panel value="all">
                <AdminAllAssessmentsTable />
            </Tabs.Panel>
            <Tabs.Panel value="users">
                <AdminUserManager />
            </Tabs.Panel>
            <Tabs.Panel value="tools">
                <AdminToolsPane />
            </Tabs.Panel>
        </Tabs>
    </FullWithDesignedSection>
}