import {useUserSearch} from "./admin-utils.js";
import { HStack, VStack} from "@seeair/shared-components";
import { TextInput} from "@mantine/core";
import React from 'react';
import {trpc} from "@seeair/shared-components";
import {AdminAssessmentsTable} from './AdminAssessmentsTable.js';
export function AdminAllAssessmentsTable() {
    const [allUsers] = trpc.ADMIN.listAllUsersAggregate.useSuspenseQuery()

    const {usersData, searchText, setSearchText} = useUserSearch(allUsers ?? [])
    return <VStack>
        <HStack>
            <TextInput
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
        </HStack>
        <AdminAssessmentsTable summaries={usersData} />

    </VStack>
}