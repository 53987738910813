import {HomeAggregate} from "@seeair/schemas";
import {FullWithDesignedSection} from './DesignBase.js';
import {COLOR_WHITE} from './Theme.js';
import React from "react";
import {getLatestFile, getLatestFinishedAssessment} from "@seeair/shared";
import {DigitalTwinSection} from './DigitalTwinSection.js';

export function ContractorViewHome({home,initiallyShowViewer}:{home:HomeAggregate,initiallyShowViewer?:boolean}) {
    const latestAssessment = getLatestFinishedAssessment(home)
    let latestRenderingFile
    if (latestAssessment != 'not_found') {
        latestRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')
    }
    return <FullWithDesignedSection background={COLOR_WHITE}>
        <DigitalTwinSection file={latestRenderingFile} home={home} initiallyShowViewer={!!initiallyShowViewer} isContractor={true}/>
    </FullWithDesignedSection>
}