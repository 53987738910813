import {
    Address,
    Assessment,
    ASSESSMENT_LABEL_BASELINE,
    AssessmentFile,
    AssessmentFilesEnum,
    HomeAggregate,
    HubspotLineItemAggregate,
    ProformaCost,
    ProformaData,
    ProformaIncentive,
    ProformaItem,
    ProformaPayback,
    ProjectAggregate,
    Recommendation,
    RecommendationIncentive,
    SeeAirUser
} from "@seeair/schemas";
import dayjs from "dayjs";
import {formatMoney, formatYears} from './util.js';

export function getLatestAssessment(assessments: Array<Assessment>): Assessment | undefined {
    let latest
    for (const assessment of assessments) {
        if (!latest || dayjs(assessment.created_at).isAfter(latest.created_at)) {
            latest = assessment
        }
    }
    return latest
}

export function recStatusToString(r: Recommendation): string {
    switch (r.status) {
        case 'done':
            return "Done"
        case 'in_progress':
            return "In Progress"
        case 'not_started':
            return "Not Started"
    }
}

export function buildShortAddress(address: Address) {
    return [address.address1, address.city].filter(Boolean).join(' ')
}

export function isHomeOwner(user: SeeAirUser | undefined | null): boolean {
    return !!user && user.role == "homeowner"
}

export function isSiteAdmin(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "admin"
}

export function isContractor(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "contractor"
}

function getLowestRecNumberInProject(project: ProjectAggregate): number {
    return project.recommendations.map(r => parseInt(r.original_rec_id)).sort()[0] ?? 0
}

export function recToNum(r: Recommendation | undefined, projects: Array<ProjectAggregate>): number {
    if (!r || !r.original_rec_id) {
        return 0
    }
    const project = projects.find(p => p.project_id == r.project_id)
    const projectModifier = project
        ? getLowestRecNumberInProject(project) - 1000
        : 10000
    const statusModifier = r.status == 'in_progress' ? -200 : r.status == 'done' ? 200 : 0
    return parseInt(r.original_rec_id) + statusModifier + projectModifier
}

/*

    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    Net_Cost_Low: z.number(),
    Net_Cost_High: z.number(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
 */
export function recIncentiveToProformaIncentive(recIncentive: RecommendationIncentive, recommendation: Recommendation): ProformaIncentive {
    let cost: ProformaCost
    if (recIncentive.percentage != null) {
        if (recommendation.rec_data.Upfront_Cost_Low == recommendation.rec_data.Upfront_Cost_High) {
            cost = {
                type: 'single',
                cost: percentageWithCap(recommendation.rec_data.Upfront_Cost_Low, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
                })
            }
        } else {
            cost = {
                type: 'range',
                low: percentageWithCap(recommendation.rec_data.Upfront_Cost_Low, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
                }),
                high: percentageWithCap(recommendation.rec_data.Upfront_Cost_High, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
                })
            }
        }
    } else {
        if (recIncentive.amountLow == recIncentive.amountHigh) {
            cost = {type: 'single', cost: recIncentive.amountLow!}
        } else {
            cost = {type: 'range', low: recIncentive.amountLow!, high: recIncentive.amountHigh!}
        }
    }
    return {
        title: recIncentive.title,
        cost,
        timeOfPurchase: recIncentive.timeOfPurchase,
        url: recIncentive.url,
        recNumbers: [recommendation.original_rec_id]
    }
}

function lineItemMatchesRecNumber(lineItem: HubspotLineItemAggregate, recNumber: string): boolean {
    return (lineItem.product?.properties?.recommendation_id ?? "").split(',').includes(recNumber)
}

export function recIncentiveWithLineItemsToProformaIncentive(recIncentive: RecommendationIncentive, recommendation: Recommendation, lineItems: Array<HubspotLineItemAggregate>): ProformaIncentive {
    const cost: number = lineItems.filter(o => lineItemMatchesRecNumber(o, recommendation.original_rec_id)).reduce((acc, v) =>
            acc + (recIncentive.discountOnRemainder
                ? parseFloat(v.properties.amount ?? "0") //if discount is on remainder apply against amount which accounts for quantity and discount
                : (parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0"))), // if discount is not on remainder, find the undiscounted total by multiplying price and quantity
        0)

    let incentiveAmount: ProformaCost
    if (recIncentive.percentage != null) {
        incentiveAmount = {
            type: 'single',
            cost: percentageWithCap(cost, {
                percentage: recIncentive.percentage,
                cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
            })
        }
    } else {
        if (recIncentive.amountLow == recIncentive.amountHigh) {
            incentiveAmount = {type: 'single', cost: recIncentive.amountLow!}
        } else {
            incentiveAmount = {type: 'range', low: recIncentive.amountLow!, high: recIncentive.amountHigh!}
        }
    }
    return {
        title: recIncentive.title,
        cost: incentiveAmount,
        timeOfPurchase: recIncentive.timeOfPurchase,
        url: recIncentive.url,
        recNumbers: [recommendation.original_rec_id]
    }
}

export function recDataToProformaData(recommendation: Recommendation): ProformaData {
    const rec_data = recommendation.rec_data
    const differenceInNetAndUpfrontCost = rec_data.Net_Cost_Low != null &&
        rec_data.Upfront_Cost_Low != null &&
        rec_data.Net_Cost_High != null &&
        rec_data.Upfront_Cost_High &&
        (rec_data.Net_Cost_Low < rec_data.Upfront_Cost_Low || rec_data.Net_Cost_High < rec_data.Upfront_Cost_High)
    let incentives: Array<ProformaIncentive>
    if (rec_data.incentives && rec_data.incentives.length > 0) {
        incentives = rec_data.incentives.map(o => recIncentiveToProformaIncentive(o, recommendation))
    } else {
        if (differenceInNetAndUpfrontCost) {
            incentives = [{
                title: "Aggregated", //TODO perhaps render this specially
                cost: toProformaCost([rec_data.Upfront_Cost_Low - rec_data.Net_Cost_Low!, rec_data.Upfront_Cost_High - rec_data.Net_Cost_High!]),
                timeOfPurchase: false,
                recNumbers: [recommendation.original_rec_id]
            }]
        } else {
            incentives = []
        }
    }
    return {
        lineItems: [],
        gross: toProformaCost([rec_data.Upfront_Cost_Low, rec_data.Upfront_Cost_High]),
        incentives,
        annual_savings: toProformaCost([rec_data.Annual_Savings_Low, rec_data.Annual_Savings_High])
    }
}

function lineItemToProformaItem(item: HubspotLineItemAggregate, recs: Array<Recommendation>): ProformaItem {
    const potentialRecIds = (item.product?.properties?.recommendation_id?.split(',') ?? []).map(o=>o.trim())
    const recNumbers = recs
        .filter(r => potentialRecIds.includes(r.original_rec_id))
        .map(r => r.original_rec_id)
    return {
        title: item.properties.name ?? "",
        price_per_unit: parseFloat(item.properties.price ?? "0"),
        quantity: parseFloat(item.properties.quantity ?? "0"),
        recNumbers
    }
}

export function lineItemsToProformaData(lineItems: Array<HubspotLineItemAggregate>, recommendations: Array<Recommendation>): ProformaData {
    const sumAnnualSavingsHigh = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_High, 0)
    const sumAnnualSavingsLow = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_Low, 0)
    const undiscountedLineItemSum = lineItems.reduce((acc, v) =>
            acc + (parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0")),
        0)
    const incentives = recommendations
        .flatMap(r => r.rec_data.incentives
            ?.map(i => recIncentiveWithLineItemsToProformaIncentive(i, r, lineItems))
            ?.filter(i => proformaCostGreaterThenZero(i.cost)) ?? [])
    return {
        lineItems: lineItems.map(o => lineItemToProformaItem(o, recommendations)),
        incentives,
        annual_savings: toProformaCost([sumAnnualSavingsLow, sumAnnualSavingsHigh]),
        gross: {type: "single", cost: undiscountedLineItemSum}
    }
}

export function isProformaCostFiniteAndNotNegative(cost: ProformaCost): boolean {
    if (cost.type == 'single') {
        return isFinite(cost.cost) && cost.cost >= 0
    }
    return isFinite(cost.high) && cost.high >= 0 && isFinite(cost.low) && cost.low >= 0
}

export function proformaCostGreaterThenZero(cost: ProformaCost): boolean {
    if (cost.type == 'single') {
        return cost.cost > 0
    } else {
        return cost.high > 0
    }
}

export function getRecNumbersForProject(project: ProjectAggregate) {
    return project.recommendations.map(r => r.original_rec_id).join(",")
}

export function toProformaCost([low, high]: [number, number]): ProformaCost {
    if (low == high) {
        return {type: 'single', cost: low}
    } else if (low < high) {
        return {type: 'range', low, high}
    } else {
        return {type: "range", low: high, high: low}
    }
}

export function getLatestFinishedAssessment(home: HomeAggregate): Assessment | 'not_found' {
    const finishedAssessments = home.assessments.filter(a => a.assessment_status == 'done' || a.assessment_status == 'pending_homeowner_review')
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]!
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(a => a.assessment_label != ASSESSMENT_LABEL_BASELINE)
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]!
        } else {
            return finishedAssessments[0]!
        }
    }
    return 'not_found'
}

export function getFileVersionsSortedByLatest(files: Array<AssessmentFile>): Array<AssessmentFile> {
    return [...files].sort((a, b) =>
        dayjs(a.created_date).unix() - dayjs(b.created_date).unix())
        .reverse()
}

export function getLatestFile(assessment: Assessment, file: AssessmentFilesEnum): AssessmentFile | 'not_found' {
    const files = (assessment.assessment_files ?? {})[file] ?? []
    return getFileVersionsSortedByLatest(files)[0] ?? 'not_found'
}

export function formatMoneyRange(cost: ProformaCost): string {
    if (cost.type == 'single') {
        return formatMoney(cost.cost)
    }
    return `${formatMoney(cost.low)} to ${formatMoney(cost.high)}`
}

export function formatYearsRange(payback: ProformaPayback): string {
    if (payback.type == "single") {
        return formatYears(payback.years)
    }
    return `${formatYears(payback.low)} to ${formatYears(payback.high)}`
}

function percentageWithCap(cost: number, {percentage, cap}: { percentage: number, cap: number }): number {
    return Math.min(cap, cost * percentage)
}

function applyIncentive(cost: number, incentive: RecommendationIncentive, lowOrHigh: keyof Pick<RecommendationIncentive, 'amountHigh' | 'amountLow'>): number {
    if (incentive.percentage != null) {
        return percentageWithCap(cost, {
            percentage: incentive.percentage,
            cap: incentive.cap ?? Number.MAX_SAFE_INTEGER
        })
    } else {
        return incentive[lowOrHigh] ?? 0
    }
}

export function calculateNetCost(data: ProformaData, includeRebates: boolean): ProformaCost {
    const sumOfIncentivesLow = data.incentives
        .filter(o => includeRebates || o.timeOfPurchase)
        .reduce(
            (acc, {cost}) =>
                acc + (cost.type == 'range'
                    ? cost.low
                    : cost.cost)
            , 0)
    const sumOfIncentivesHigh = data.incentives
        .filter(o => includeRebates || o.timeOfPurchase)
        .reduce(
            (acc, {cost}) =>
                acc + (cost.type == 'range'
                    ? cost.high
                    : cost.cost)
            , 0)

    if (sumOfIncentivesHigh == sumOfIncentivesLow && data.gross.type == 'single') {
        return {type: 'single', cost: data.gross.cost - sumOfIncentivesLow}
    } else {
        return {
            type: 'range',
            high: (data.gross.type == 'range' ? data.gross.high : data.gross.cost) - sumOfIncentivesHigh,
            low: (data.gross.type == 'range' ? data.gross.low : data.gross.cost) - sumOfIncentivesLow
        }
    }

}

export function calculatePaybackPeriod(data: ProformaData): ProformaPayback {
    const annualSavingAvg = data.annual_savings.type == 'range'
        ? (data.annual_savings.high + data.annual_savings.low / 2)
        : data.annual_savings.cost
    const net = calculateNetCost(data, true)
    return net.type == "single"
        ? {type: 'single', years: net.cost / annualSavingAvg}
        : {type: 'range', low: net.low / annualSavingAvg, high: net.high / annualSavingAvg}
}
