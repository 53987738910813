import {useState, useMemo} from 'react'
import {AdminAssessmentSummary} from "@seeair/schemas";

export function useUserSearch(_usersData: Array<AdminAssessmentSummary>) {
	const [searchText, setSearchText] = useState('')

	/**
	 * Search rules TLDR:
	 * 		case insensitive search across `str` fields (email, name, address, order_number, customer_email, location_id etc.)
	 * 		case sensitive EXACT search across `id` fields (user_id, home_id, asmt_id, order_id, etc.)
	 */
	const usersData = useMemo(() => {
		return !searchText
			? _usersData
			: _usersData.filter((user) => {
					const str = [
						user.email,
						user.name,
						(user.home.address?.address1 ?? "")
					].join('\n')

					const ids = [
						user.id
					]

					return (
						str.toLowerCase().includes(searchText.toLowerCase()) ||
						ids.includes(searchText)
					)
				})
	}, [_usersData, searchText])

	return {usersData, searchText, setSearchText}
}
