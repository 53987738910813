import SuperJSON from "superjson";
import dayjs, {Dayjs} from "dayjs";

export function initSuperJSON() {
    SuperJSON.registerCustom(
        {
            isApplicable: (v): v is Dayjs => dayjs.isDayjs(v),
            serialize: (v) => v.toISOString(),
            deserialize: (v) => dayjs(v),
        },
        'dayjs'
    )
}