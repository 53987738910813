import {HomeAggregate, Share} from "@seeair/schemas";
import {Button, InputError, Loader, NativeSelect, Select, TextInput} from "@mantine/core";
import {trpc} from "./trpc.js"
import {useForm, zodResolver} from "@mantine/form";
import {z} from "zod";
import {
    HDivider,
    HStack,
    TextBase,
    TextLg,
    TextSmItalic,
    VStack
} from "./DesignBase.js";
import dayjs from "dayjs";
import React, {useContext, useState} from 'react'
import {getUseMutationOpt} from './mutationHelper.js';
import {UserDataContext} from './UserDataProvider.js';
import {DeleteButton} from './MantineExtensions.js';
import {Badge, DesignedButton} from './DesignComponents.js';
import {isSiteAdmin} from "@seeair/shared";


const readWriteProps = {
    className: "w-32",
    classNames: {dropdown: "w-40"},
    data: [
        {label: 'Read', value: 'read'},
        {label: 'Read / Write', value: 'read-write'},
    ],
} as const
function getShareDisplay(share:Share):string{
    if(share.target_email.length > 0){
        return share.target_email
    } else if(share.granted_user_id){
        return `user_id: ${share.granted_user_id}`
    } else {
        return `company_id: ${share.granted_company_id}`
    }
}
export function SharePanel({home}: { home: HomeAggregate }) {
    const {user} = useContext(UserDataContext)
    const utils = trpc.useUtils()
    const addShare = trpc.HOMEOWNER.addShare.useMutation(getUseMutationOpt(utils))
    const updateShare = trpc.HOMEOWNER.updateShare.useMutation(getUseMutationOpt(utils))
    const removeShare = trpc.HOMEOWNER.removeShare.useMutation(getUseMutationOpt(utils))
    console.log(`share panel user: ${JSON.stringify(user)}`)
    const form = useForm({
        initialValues: {
            target_email: '',
            access_level: 'read-write' as 'read' | 'read-write',
        },
        validate: zodResolver(
            z.object({
                target_email: z.string().email(),
                access_level: z.enum(['read', 'read-write']),
            })
        ),
    })


    if (home.permissions && !home.permissions.has('owner')) {
        // Should never get here, just in case.
        return (
            <VStack>
                <TextBase>You do not have permission to share this home, please ask the owner to do so.</TextBase>
            </VStack>
        )
    }

    return (
        <VStack>
            <VStack>
                <TextLg>People with access</TextLg>
                {
                    !home.shares || home.shares.length === 0
                        ? <TextSmItalic>You have not shared with anybody yet</TextSmItalic>
                        : <VStack>
                            {home.shares.map((share) => {
                                const isGranted = !!share.granted_user_id || !!share.granted_company_id
                                const isExpiredIfNotGranted = dayjs(
                                    share.verification_code_expires_at
                                ).isBefore()
                                return (
                                    <HStack leftCenter key={share.verification_code}>
                                        <TextBase>{getShareDisplay(share)}</TextBase>
                                        <Badge
                                            classNames="mx-16"
                                            green={isGranted}
                                            gray={!isGranted}
                                        >
                                            {
                                                isGranted
                                                    ? "Granted"
                                                    : isExpiredIfNotGranted
                                                        ? "Expired"
                                                        : "Pending"
                                            }
                                        </Badge>
                                        <Select
                                            {...readWriteProps}
                                            value={share.access_level}
                                            onChange={(val) => {
                                                if (!val) {
                                                    return
                                                }
                                                if (!(val === 'read' || val === 'read-write')) {
                                                    throw new Error('Invalid access level')
                                                }
                                                updateShare.mutate({
                                                    home_id: share.home_id,
                                                    target_email: share.target_email,
                                                    access_level: val,
                                                })
                                            }}
                                        />
                                        <DeleteButton
                                            isPending={removeShare.isPending && removeShare.variables.target_email === share.target_email}
                                            onClick={() =>
                                                removeShare.mutate({
                                                    home_id: share.home_id,
                                                    target_email: share.target_email,
                                                })
                                            }
                                        />
                                    </HStack>
                                )
                            })}
                        </VStack>
                }
            </VStack>
            <HDivider/>
            <VStack>
                <TextLg>Send invite</TextLg>
                <form
                    onSubmit={form.onSubmit((values) => {
                        addShare.mutate({home_id: home.home_id, ...values})
                    })}
                >
                    <HStack>
                        <TextInput
                            label="Email"
                            {...form.getInputProps('target_email')}
                        />
                        <Select
                            label="Access"
                            {...readWriteProps}
                            {...form.getInputProps('access_level')}
                        />
                    </HStack>
                    <Button type="submit">
                        Share&nbsp;{addShare.isPending && <Loader size="1rem"/>}
                    </Button>
                    {addShare.error && (
                        <InputError>{addShare.error.message}</InputError>
                    )}
                </form>
            </VStack>
            {isSiteAdmin(user) && <React.Fragment>
                <HDivider/>
                <AdminSharePanel home={home}/>
            </React.Fragment>}
        </VStack>
    )
}

export function AdminSharePanel({home}: { home: HomeAggregate }) {
    const [selectedUserId, setSelectedUserId] = useState(undefined as string | undefined)
    const [accessLevel, setAccessLevel] = useState('read-write')
    const [allUsers] = trpc.ADMIN.listUsers.useSuspenseQuery()
    const {mutate: setShare, isPending} = trpc.ADMIN.setShare.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <TextLg>Add Permissions</TextLg>
        <HStack>
            <NativeSelect
                label="User"
                value={selectedUserId}
                onChange={(event) => setSelectedUserId(event.currentTarget.value)}
                data={allUsers.map(u => ({label: `${u.name} - ${u.email}`, value: u.id}))}
            />
            <NativeSelect
                label="Access"
                value={accessLevel}
                onChange={(event) => setAccessLevel(event.currentTarget.value)}
                data={[
                    {label: 'Read', value: 'read'},
                    {label: 'Read / Write', value: 'read-write'},
                ]}
            />
        </HStack>
        <DesignedButton
            disabled={isPending}
            onClick={() => setShare({
                home_id:home.home_id,
                granted_user_id:selectedUserId!,
                granted_company_id:null,
                access_level:accessLevel as 'read'|'read-write',
                target_email:allUsers.find(u=>u.id==selectedUserId)?.email ?? ""
            })}>
            Share&nbsp;{isPending && <Loader size="1rem"/>}
        </DesignedButton>
    </VStack>
}