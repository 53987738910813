import {createFileRoute} from '@tanstack/react-router'
import {trpc} from "@seeair/shared-components";
import {BlogPage, BlogPageSkeleton} from "@seeair/homeowner-components";
import {AbsoluteCenterStack, TextError} from "@seeair/shared-components";
import React from 'react'
export const Route = createFileRoute('/blog/_blog/$blog_id')({
    component: function BlogPageComponent() {
        const {blog_id} = Route.useParams()
        const {data: blog, error} = trpc.PUBLIC.getBlog.useQuery({blog_id})
        if (error) {
            return <AbsoluteCenterStack>
                <TextError>{error.message}</TextError>
            </AbsoluteCenterStack>
        } else if(blog) {
            return <BlogPage blog={blog}/>
        } else {
            return <BlogPageSkeleton />
        }

    }
})