import {COLOR_WHITE} from './Theme.js';
import {FullWithDesignedSection} from './DesignBase.js';
import React from 'react'
import {trpc} from "./trpc.js"
import {Table} from "@mantine/core";
import {Link} from "@tanstack/react-router";
import {buildShortAddress} from "@seeair/shared";

export function ContractorDashboard() {
    const [homes] = trpc.CONTRACTOR.listHomes.useSuspenseQuery()

    return <FullWithDesignedSection background={COLOR_WHITE}>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Address</Table.Th>
                    <Table.Th>Link</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {homes.map(h=><Table.Tr key={h.home_id}>
                    <Table.Td>
                        {buildShortAddress(h.address)}
                    </Table.Td>
                    <Table.Td>
                        <Link
                            to="/contractor/home/$home_id"
                            params={{
                                home_id: h.home_id
                            }}
                        >Details</Link>
                    </Table.Td>
                </Table.Tr>)}
            </Table.Tbody>
        </Table>
    </FullWithDesignedSection>
}