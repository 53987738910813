import {
    EnergyChart,
    HDivider,
    HStack,
    RecommendationsAccordion,
    ShareButton,
    SharePanel,
    Text3Xl,
    TextBase,
    TextLg, TextRXl,
    VStack
} from "@seeair/shared-components";
import React, { useState} from "react";
import {
    HomeAggregate
} from "@seeair/schemas";
import { Modal, SegmentedControl, Tabs} from "@mantine/core";
import {ProjectsAccordion} from "@seeair/shared-components"
import {IconHammer, IconSparkles} from "@tabler/icons-react";
import {buildShortAddress, formatMoney, formatNumber, getLatestFile, getLatestFinishedAssessment} from "@seeair/shared";
import { DigitalTwinSection} from "@seeair/shared-components";

type Show = 'usage' | 'cost'

export function RecommendationsPage({home,initiallyShowViewer}: { home: HomeAggregate,initiallyShowViewer?:boolean }) {
    // const {data:threeDThumbnailSrc,mutate:load3dThumb} = trpc.HOMEOWNER.getAssessmentFileDownloadUrl.useMutation()
    const [currentModal, setCurrentModal] = useState<'share'>()
    const [tab, setTab] = useState("recommendations" as "recommendations"|"projects")
    const [selectedProjectId, setSelectedProjectId] = useState(null as string | null)

    const latestAssessment = getLatestFinishedAssessment(home)
    let electrification, latestRenderingFile
    if (latestAssessment != 'not_found') {
        latestRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')
        electrification = latestAssessment.assessment_data?.electrification
    }
    const costGenerated = electrification?.energy_chart?.some(o => o.Heating_Cost != null)
    const [show, setShow] = useState(costGenerated ? 'cost' : 'usage' as Show)
    let electricAnnual, gasAnnual
    if (latestAssessment != 'not_found' && electrification) {
        if (show == "usage") {
            electricAnnual = `${formatNumber(electrification.energy_use.Electric, 0)} kWh`
            gasAnnual = `${formatNumber(electrification.energy_use.Gas, 0)}M BTU`
        } else {
            electricAnnual = `${formatMoney(electrification.energy_cost.Electric)}`
            gasAnnual = `${formatMoney(electrification.energy_cost.Gas)}`
        }
    }
    // useEffect(() => {
    //     if (latestAssessment != 'not_found') {
    //         load3dThumb({home_id:home.home_id,assessment_id:latestAssessment.assessment_id,file_enum:'capture_rendering_enhanced.thumbnail'})
    //     }
    // }, [latestAssessment,load3dThumb,home]);
    const navigateToProject = (project_id:string)=>{
        setTab("projects")
        setSelectedProjectId(project_id)
    }
    return <VStack classNames="flex-grow bg-white">
        <HStack center>
            <TextRXl>{buildShortAddress(home.address)}</TextRXl>
            {(home.permissions?.has('owner') ?? false) && (
                <ShareButton onClick={() => setCurrentModal('share')}/>
            )}
        </HStack>
        <VStack center>
            <HStack center>
                <Text3Xl light center>Heating & Cooling Energy Costs</Text3Xl>
                {costGenerated && <SegmentedControl className="ml-8" value={show} onChange={(v) => setShow(v as Show)}
                                                    data={[{label: "Cost", value: 'cost'}, {
                                                        label: "Usage",
                                                        value: 'usage'
                                                    }]}/>}
            </HStack>
            {
                electrification
                    ? <React.Fragment>
                        <HStack baseline center>
                            <TextLg classNames="pr-4">Annual Electric Usage:</TextLg>
                            <TextLg light>{electricAnnual}</TextLg>
                            <TextLg classNames="pr-4 pl-8">
                                Annual Gas Usage:&nbsp;
                            </TextLg>
                            <TextLg light>{gasAnnual}</TextLg>

                        </HStack>
                        <EnergyChart
                            mobile
                            width={850}
                            height={350}
                            energy_chart={electrification.energy_chart}
                            show={show}
                        />
                    </React.Fragment>
                    : <TextBase>Electrification Data Missing</TextBase>
            }
        </VStack>
        <HDivider/>
        <VStack center>
            <Text3Xl light center>Digital Twin</Text3Xl>
            <DigitalTwinSection file={latestRenderingFile} home={home} initiallyShowViewer={!!initiallyShowViewer} isContractor={false}/>
        </VStack>

        <HDivider/>
        <Text3Xl light center>Home Improvement Plan</Text3Xl>
        <Tabs value={tab} onChange={(v)=>setTab(v as "recommendations"|"projects")}>
            <Tabs.List classNames={{list: "justify-center"}}>
                <Tabs.Tab value="recommendations" leftSection={<IconSparkles/>}>
                    Recommendations
                </Tabs.Tab>
                <Tabs.Tab value="projects" leftSection={<IconHammer/>}>
                    Projects
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="recommendations">
                <VStack center>
                    <VStack classNames="max-w-screen-xl">
                        {
                            home.recommendations.length == 0
                                ?
                                <TextBase>Your home is healthy and energy efficient, thanks for doing your part to save
                                    the
                                    world!</TextBase>
                                : <RecommendationsAccordion
                                    recs={home.recommendations}
                                    projects={home.projects}
                                    filteredRecs={[]}
                                    splitView={false}
                                    navigateToProject={navigateToProject}
                                />
                        }
                    </VStack>
                </VStack>
            </Tabs.Panel>
            <Tabs.Panel value="projects">
                <VStack center>
                    <VStack classNames="max-w-screen-xl">
                        {
                            home.recommendations.length == 0
                                ? <TextBase>We have no planned projects yet, please contact us to get started</TextBase>
                                : <ProjectsAccordion projects={home.projects} selectedProjectId={selectedProjectId}/>
                        }
                    </VStack>
                </VStack>
            </Tabs.Panel>
        </Tabs>
        <Modal title="Shares" opened={currentModal == 'share'} onClose={() => setCurrentModal(undefined)}>
            <SharePanel home={home}/>
        </Modal>
    </VStack>
}


