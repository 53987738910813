import {HubspotBlogAggregate} from "@seeair/schemas";
import {
    COLOR_WHITE,
    FullWithDesignedSection,
    HStack,
    TextBase,
    TextLg, TextRXl,
    VStack
} from "@seeair/shared-components";
import React from "react";
import {BlogSection} from './BlogSection.js';
import {Helmet} from "react-helmet-async";
import {Skeleton} from "@mantine/core";
import {shortDateFormat} from "@seeair/shared";

export function BlogPageSkeleton() {
    return <VStack>
        <FullWithDesignedSection background={COLOR_WHITE}>
            <Skeleton height={24} width="70%" mb={20} radius="xl"/>
            <VStack classNames="ml-4">
                <HStack center>
                    <Skeleton circle height="6rem"/>
                    <VStack>
                        <HStack classNames="items-center">
                            <Skeleton height={8} width={75} mt={6} ml={12} radius="xl"/>
                        </HStack>
                        <Skeleton height={8} width={50} mt={6} ml={12} radius="xl"/>
                    </VStack>
                </HStack>
            </VStack>
            <VStack classNames="mt-8">
                <Skeleton height={16} width={100} radius="xl"/>
                <Skeleton height={10} mt={12} radius="xl"/>
                <Skeleton height={10} mt={8} radius="xl"/>
                <Skeleton height={10} mt={8} radius="xl"/>
                <Skeleton height={10} mt={8} radius="xl"/>
                <Skeleton height={10} mt={8} width="70%" radius="xl"/>
            </VStack>
        </FullWithDesignedSection>
    </VStack>
}

export function BlogPage({blog}: { blog: HubspotBlogAggregate }) {
    return <VStack>
        <FullWithDesignedSection background={COLOR_WHITE}>
            <Helmet>
                <title>SeeAir Blog - {blog.name}</title>
                <meta name="description" content={blog.metaDescription ?? blog.name}/>
                <link rel="canonical" href={`https://seeair.com/blog/${blog.id}`}/>
            </Helmet>

            <VStack>
                <TextRXl>{blog?.name}</TextRXl>
                <HStack center>
                    <img alt="author-headshot"
                         src={blog.author.avatar || '/design-assets/avatar-placeholder.png'}
                         style={{
                             borderRadius: '100%',
                             height: "6rem",
                             width: "6rem"
                         }}/>
                    <VStack classNames="ml-4">
                        <HStack classNames="items-center">
                            <TextLg m0 classNames="mr-4">
                                {blog.author.displayName}
                            </TextLg>
                        </HStack>
                        {blog.publishDate && <TextBase light m0>{shortDateFormat(blog.publishDate)}</TextBase>}
                    </VStack>
                </HStack>
                <div dangerouslySetInnerHTML={{'__html': blog.postBody}}/>
            </VStack>
        </FullWithDesignedSection>
        <BlogSection title={"Recent Posts"} skipId={blog?.id}/>
    </VStack>
}