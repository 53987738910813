import React, {useEffect} from 'react'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {trpc} from "@seeair/shared-components";
import {HomesList} from "@seeair/shared-components";

export const Route = createFileRoute('/homes/_homes/')({
	component: LandingHomeList
})

function LandingHomeList() {
	const navigate = useNavigate()
	const [{homes}] = trpc.HOMEOWNER.getHomesAggregate.useSuspenseQuery()
	useEffect(() => {
		if(homes.length == 1) {
			(async ()=>await navigate({to: '/homes/$home_id', params: {home_id: homes[0]!.home_id}}))().catch(()=>{})
		}
	}, [homes,navigate]);
	return <HomesList homes={homes} />
}