import {type Client as SentryClient} from '@sentry/types'
import {z} from 'zod'
import {type DbType, SeeAirUser, SeeAirUserSchema} from "@seeair/schemas";
import {SQSClient} from "@aws-sdk/client-sqs";
import {S3Client} from "@aws-sdk/client-s3";
import {SageMakerRuntimeClient} from "@aws-sdk/client-sagemaker-runtime";
import {FetchCreateContextFnOptions} from '@trpc/server/adapters/fetch'
import {IAutodeskClient} from './iAutodeskClient.js';

export const envSchema = z.object({
    VITE_DEPLOYMENT_ENV: z.enum(['local', 'dev', 'prod']),
    AUTH_SECRET: z.string(),
    /*
     * GOOGLE_CLIENT_ID,GOOGLE_CLIENT_SECRET
     * create oauth credentials in gcp console
     */
    GOOGLE_CLIENT_ID: z.string(),
    GOOGLE_CLIENT_SECRET: z.string(),
    /*
     * APPLE_CLIENT_ID,APPLE_CLIENT_SECRET
     * create registered in apple dev portal, secret generated in playground (6months expiry)
     */
    APPLE_CLIENT_ID: z.string(),
    APPLE_CLIENT_SECRET: z.string(),
    /*
     * GOOGLE_API_KEY
     * !enable the maps and places api in gcp console
     * create api credentials in gcp console
     */
    GOOGLE_API_KEY: z.string(),
    /*
     * GOOGLE_SERVICE_ACCOUNT_JSON
     * !enable the google sheets api in gcp console
     * create a service account in gcp console
     * add it to the following spreadsheets
     *
     * Thresholds               | Viewer | https://docs.google.com/spreadsheets/d/1RxnDPNY7VGw5VPY_ifBSnMI5-WW7VnSU-xRaY3pb0Kw/
     * Home Details             | Viewer | https://docs.google.com/spreadsheets/d/1mq91MTd7hIu84F_YVSaP7zj2Haag5rx7dbb1sbwE8_U/
     * SEEAIR Recommendations   | Editor | https://docs.google.com/spreadsheets/d/1pHD0u8Jd_8O101IKQiUBudKpM-JJA695MVeFuVYH5wo/
     */
    GOOGLE_SERVICE_ACCOUNT_JSON: z.string(),
    // db
    // DATABASE_HOST: z.string(),
    // DATABASE_USERNAME: z.string(),
    // DATABASE_PASSWORD: z.string(),
    // DATABASE_NAME: z.string(),
    /*
     * NEON_CONN_STRING
     * make sure the seeair-db database is included in the connection string and not the default database
     */
    NEON_CONN_STRING: z.string(),
    // other
    RENTCAST_API_KEY: z.string(),
    /*
     * AWS_ACCESS_KEY_ID,AWS_SECRET_ACCESS_KEY,AWS_REGION
     * for calling r-analysis lambda
     */
    AWS_ACCESS_KEY_ID: z.string(),
    AWS_SECRET_ACCESS_KEY: z.string(),
    AWS_REGION:z.string(),
    WEB_SENTRY_DSN: z.string(),
    STATSIG_SDK_KEY: z.string(),
    RESEND_API_KEY: z.string(),
    SQS_CUBICASA_WEBHOOK_URL: z.string().url(),
    SQS_HUBSPOT_WEBHOOK_URL: z.string().url(),
    SQS_TYPEFORM_WEBHOOK_URL: z.string().url(),
    SQS_UTILITYAPI_WEBHOOK_URL: z.string().url(),
    SQS_AUTODESK_WEBHOOK_URL: z.string().url(),
    SQS_EXTERNAL_SYNC_URL: z.string().url(),
    SQS_S3_CAPTURE_URL: z.string().url(),
    HUBSPOT_API_TOKEN: z.string(),
    CUBI_CAPTURE_API_KEY: z.string(),
    S3_BUCKET_CAPTURE: z.string(),
    S3_BUCKET_ASSESSMENT_FILES: z.string(),
    AUTODESK_CLIENT_ID: z.string(),
    AUTODESK_CLIENT_SECRET: z.string(),
    BUCKET_SEEAIR_BIM: z.string(),
    CLOUDFLARE_KV_API_TOKEN: z.string(),
    CF_ACCOUNT_ID: z.string(),
    CLOUDFLARE_KV_NAMESPACE: z.string(),
    SAGE_ENDPOINT_PHOTO_CLASSIFIER: z.string(),
    // Bindings
    KV: z.custom<KVNamespace>(
        (val) =>
            val &&
            typeof val === 'object' &&
            'getWithMetadata' in val &&
            typeof val.getWithMetadata === 'function' &&
            'get' in val &&
            typeof val.get === 'function' &&
            'put' in val &&
            typeof val.put === 'function'
    ),
    // Cloudflare Variables
    CF_PAGES: z.literal(1),
    CF_PAGES_COMMIT_SHA: z.string(),
    CF_PAGES_BRANCH: z.string(),
    CF_PAGES_URL: z.string(),
})
export const CfEnvCheckTypesToOmit = {KV: true,CF_PAGES:true,CF_PAGES_COMMIT_SHA:true,CF_PAGES_BRANCH:true,CF_PAGES_URL:true} as const
export type CfEnv = z.infer<typeof envSchema>

export type CfData = {
    DB: DbType
    SQS: SQSClient
    S3: S3Client
    SAGE: SageMakerRuntimeClient
    APS: IAutodeskClient
    // LAMBDA: LambdaClient
    // DB_CONNECTION: Client
    user?: SeeAirUser
    requestId: number
    sentry: SentryClient
}

export type CfEventCtx<P extends string = never> = EventContext<CfEnv, P, CfData>
export type CfFn<Params extends string = any> = PagesFunction<CfEnv, Params, CfData>

export const createContext =
    (ctx: CfEventCtx) =>
        ({req, resHeaders}: FetchCreateContextFnOptions) => ({...ctx})

export type ApiCtx = ReturnType<ReturnType<typeof createContext>>




const SessionSchema = z.object({user: SeeAirUserSchema, expires: z.string()})
export type SessionData = z.infer<typeof SessionSchema>

export const SessionRespSchema = z
    .union([SessionSchema, z.object({message: z.string().optional()})])
    .optional()
    .nullable()
