import {
    Badge,
    DeleteWidget,
    DesignedButton,
    getUseMutationOpt,
    HStack,
    LineItemTable,
    ProjectIcon,
    Text2Xl,
    TextBase,
    TextLg,
    TextSm,
    VStack
} from "@seeair/shared-components";
import {HomeAggregate, ProjectAggregate} from "@seeair/schemas";
import {Accordion, AccordionControl, AccordionPanel, Loader, Menu, TextInput} from "@mantine/core";
import {RecControl} from './AdminRecommendationsPanel.js';
import {trpc} from "@seeair/shared-components";
import {IconRowInsertBottom, IconRowRemove} from "@tabler/icons-react";
import React, {useState} from "react";
import {getRecNumbersForProject} from "@seeair/shared";

export function AdminProjectsPanel({home}: { home: HomeAggregate }) {
    const [project_title, setProjectTitle] = useState("")
    const {
        isPending: isCreateProjectPending,
        mutate: createProject
    } = trpc.ADMIN.createProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <ProjectAccordion home={home}/>
        <HStack leftCenter>
            <DesignedButton
                disabled={isCreateProjectPending || project_title.length < 1}
                onClick={() => createProject({home_id: home.home_id, project_title})}
            >New Project</DesignedButton>
            <TextInput
                classNames={{input: "w-72"}}
                label="Project Title"
                value={project_title}
                onChange={(event) =>
                    setProjectTitle(event.currentTarget.value)}/>
        </HStack>
    </VStack>
}

function ProjectAccordion({home}: { home: HomeAggregate }) {
    return <Accordion>
        {(home.projects ?? []).map((p, i) => <Accordion.Item key={i} value={`${i}`}>
            <AccordionControl><ProjectControl project={p}/></AccordionControl>
            <AccordionPanel><ProjectPanel project={p} home={home}/></AccordionPanel>
        </Accordion.Item>)}
    </Accordion>
}

function ProjectControl({project}: { project: ProjectAggregate }) {
    return <HStack leftCenter>
        <Text2Xl>{project.recommendations.length}</Text2Xl>
        <TextLg classNames="ml-4">{project.project_title}</TextLg>
        <TextBase classNames="ml-4">{getRecNumbersForProject(project)}</TextBase>
    </HStack>
}

export function DeleteProjectWidget({project}: { project: ProjectAggregate }) {
    const {mutate: deleteProject} = trpc.ADMIN.deleteProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {mutate: renameProject} = trpc.ADMIN.renameProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <DeleteWidget
        name={`${project.project_title}`}
        deleteTitle={`Delete Project (${getRecNumbersForProject(project) || "none"})`}
        deleteFn={() => {
            deleteProject({project_id: project.project_id})
        }}
        renameTitle={`Rename Project`}
        renameFn={(name) => {
            renameProject({project_id: project.project_id, project_title: name})
        }}/>
}

function ProjectPanel({project, home}: { project: ProjectAggregate, home: HomeAggregate }) {
    const {
        isPending: isSetProjectPending,
        mutate: setProject
    } = trpc.ADMIN.setProjectForRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <HStack>
            <Badge classNames="ml-4">{project.hubspot_deal_stage ?? "No Deal"}</Badge>
            <Badge>{project.hubspot_quote_status ?? "No Quote"}</Badge>
            <Badge classNames="ml-4">{project.hubspot_quote_signature_status ?? "No Quote"}</Badge>
            <Badge classNames="ml-4">{project.hubspot_quote_payment_status ?? "No Quote"}</Badge>
            <DeleteProjectWidget project={project}/>
        </HStack>
        <VStack classNames="border-1">
            {(project.recommendations ?? []).map(r => <HStack key={r.recommendation_id}>
                <RecControl rec={r} proj={project}/>
                <DesignedButton
                    onClick={() => setProject({
                        home_id: project.home_id,
                        recommendation_id: r.recommendation_id,
                        project_id: null,
                        selected: false
                    })}
                    disabled={isSetProjectPending}
                >{isSetProjectPending ? <Loader size="sm"/> : <IconRowRemove/>}</DesignedButton>
            </HStack>)}
        </VStack>
        <div className="relative">
            <Menu classNames={{dropdown: "mt-16"}} withinPortal={false}>
                <Menu.Target>
                    <div>
                        <DesignedButton
                            disabled={isSetProjectPending}
                        >{isSetProjectPending ? <Loader size="sm"/> : <IconRowInsertBottom/>}</DesignedButton>
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>Ungrouped Recommendations</Menu.Label>
                    {(home.recommendations ?? []).filter(r => !r.project_id).map(r =>
                        <Menu.Item
                            key={r.recommendation_id}
                            leftSection={<ProjectIcon sm rec_number={r.original_rec_id}/>}
                            onClick={() => setProject({
                                home_id: project.home_id,
                                recommendation_id: r.recommendation_id,
                                project_id: project.project_id,
                                selected: true
                            })}
                        >
                            {r.title}
                        </Menu.Item>)}
                </Menu.Dropdown>
            </Menu>
        </div>
        <TextLg medium>Deal Line Items</TextLg>
        <LineItemTable line_items={project.hubspot_deal_line_items ?? []}/>
        <TextLg>Deal Amount {project.hubspot_deal_amount}</TextLg>
        <TextLg medium>Quote Line Items</TextLg>
        <LineItemTable line_items={project.hubspot_quote_line_items ?? []}/>
        <TextLg>Quote Amount {project.hubspot_quote_amount}</TextLg>
        <TextSm>{project.hubspot_last_modified}</TextSm>
    </VStack>
}