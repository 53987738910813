import {createFileRoute, Outlet} from "@tanstack/react-router";
import {ContractorUserRedirectToSignInCheck, Layout, SheetsDataProvider} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";

export const Route = createFileRoute('/contractor/_contractor')({
    component: function ContractorParentRoute() {
        return <Layout>
            <ContractorUserRedirectToSignInCheck>
                <SheetsDataProvider>
                    <Helmet>
                        <title>SeeAir Admin</title>
                        <meta name="description"
                              content="SeeAir Contractor Dashboard"/>
                    </Helmet>
                    <Outlet/>
                </SheetsDataProvider>
            </ContractorUserRedirectToSignInCheck>
        </Layout>
    }
})