import {createFileRoute} from '@tanstack/react-router'


import React from 'react'
import {AdminDashboard} from "@seeair/admin-components";

export const Route = createFileRoute('/admin/_admin/')({
    component: function AdminDashboardComponent(){
        return <AdminDashboard />
    }
})
