import {trpc} from "@seeair/shared-components";
import {NativeSelect, Table} from "@mantine/core";
import React from "react";
import {SeeAirUser, UserRole, UserRolesSchema} from "@seeair/schemas";
import {getUseMutationOpt, Text3Xl, VStack} from "@seeair/shared-components";

export function AdminUserManager() {
    const [users] = trpc.ADMIN.listUsers.useSuspenseQuery()
    const {isPending, mutate} = trpc.ADMIN.setUserRole.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <Text3Xl>User Manager</Text3Xl>
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Role</Table.Th>
                    <Table.Th>Company</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    [...users]
                        .sort(sortUsers)
                        .map(u => <Table.Tr key={u.id}>
                        <Table.Th>{u.email}</Table.Th>
                        <Table.Th>{u.name}</Table.Th>
                        <Table.Th><NativeSelect
                            disabled={isPending}
                            value={u.role ?? undefined}
                            onChange={(e) => mutate({
                                user_id: u.id,
                                role: e.currentTarget.value as UserRole
                            })}
                            data={["",...UserRolesSchema.options]}
                        /></Table.Th>
                            <Table.Th>{u.company_id}</Table.Th>
                    </Table.Tr>)
                }
            </Table.Tbody>
        </Table>
    </VStack>
}
function sortUsers(u1:SeeAirUser,u2:SeeAirUser):number {
    const roleCompare = ((u1.role ?? "homeowner") as string).localeCompare((u2.role ?? "homeowner") as string)
    const emailCompare = u1.email.localeCompare(u2.email,undefined,{sensitivity:"base"})
    if(roleCompare == 0) {
        return emailCompare
    }
    return roleCompare
}