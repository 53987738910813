import {createFileRoute} from '@tanstack/react-router'
import React from "react";
import {BlogIndexPage} from "@seeair/homeowner-components";
import {trpc} from "@seeair/shared-components";

export const Route = createFileRoute('/blog/_blog/')({
    component: function BlogIndexComponent() {
        const {data,error} = trpc.PUBLIC.getAllBlogs.useQuery()
        return <BlogIndexPage blogs={data} error={error}/>
    }
})