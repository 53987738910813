import {DesignedButton, getUseMutationOpt, HStack, showSuccessNotification, VStack} from "@seeair/shared-components";
import {trpc} from "@seeair/shared-components";
import * as Sentry from '@sentry/react'
import {SeverityLevel} from '@sentry/react'
import React, {useState} from 'react'
import {NativeSelect} from "@mantine/core";

export function AdminToolsPane() {
    const [level, setLevel] = useState("info" as SeverityLevel)
    const {mutate, isPending} = trpc.ADMIN.sentryTest.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack center>
        <HStack>
            <DesignedButton onClick={() => {
                Sentry.captureMessage("Test Frontend Message", level)
                showSuccessNotification()
            }}>Sentry FrontEnd Test</DesignedButton>
            <NativeSelect value={level} onChange={(e) => setLevel(e.currentTarget.value as SeverityLevel)}
                          data={["fatal", "error", "warning", "log", "info", "debug"] as Array<SeverityLevel>}/>
        </HStack>
        <DesignedButton disabled={isPending} onClick={() => {
            console.log("mutate called")
            mutate()
        }}>Sentry Backend Test</DesignedButton>
    </VStack>
}