import { Text2Xl, VStack} from "@seeair/shared-components";
import React from 'react'
import {UnstyledButton} from "@mantine/core";

export function AppRedirect({url}: { url: string }) {
    // const cookies  = parse(document.cookie)
    // const token = cookies["__Secure-authjs.session-token"]||cookies["authjs.session-token"]||"none"
    return <VStack center classNames="flex-grow bg-white h-full min-h-96">
        <UnstyledButton
            className="bg-black rounded text-white text-base font-semibold no-underline px-5 flex flex-row items-center"
            onClick={() => {
                if (url) {
                    window.open(url)
                } else {
                    alert("Something went wrong, please try to log in again or contact support for assistance")
                }
            }}>
            <img className="inline-block mx-2 my-4 w-6 h-6" src={"/design-assets/apple.png"}/><Text2Xl m0>Launch SeeAir App</Text2Xl>
        </UnstyledButton>

    </VStack>

}
