import {Button, Loader, Textarea} from "@mantine/core";
import {Assessment, HomeAggregate} from "@seeair/schemas";
import {useState} from "react";
import {trpc} from "@seeair/shared-components";
import {getUseMutationOpt, VStack} from "@seeair/shared-components";
import React from 'react'

export function AssessmentNotesPanel({home, assessment}: { home: HomeAggregate, assessment: Assessment }) {
    const [text, setText] = useState("")
    const {isPending, mutate} = trpc.ADMIN.addAssessmentNote.useMutation(getUseMutationOpt(trpc.useUtils(),()=>setText("")))
    return (
        <VStack>
            <div>
                {
                    assessment.assessment_notes
                        ? Object.values(assessment.assessment_notes || {}).map(n => <div key={n.created_date}>
                            <p>{n.message}</p>
                            <span>{n.created_by}</span>
                            <span>{n.created_date}</span>
                        </div>)
                        : <span>no notes</span>
                }
            </div>
            <div>
                <Textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <Button onClick={() => mutate({assessment_id: assessment.assessment_id, message: text})}>
                    {isPending ? <Loader/> : "Save"}
                </Button>
            </div>
        </VStack>)
}