/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TermsImport } from './routes/terms'
import { Route as QuizImport } from './routes/quiz'
import { Route as PrivacyImport } from './routes/privacy'
import { Route as AboutImport } from './routes/about'
import { Route as IndexImport } from './routes/index'
import { Route as WebviewAppRedirectImport } from './routes/webview/app-redirect'
import { Route as HomesHomesImport } from './routes/homes/_homes'
import { Route as ContractorContractorImport } from './routes/contractor/_contractor'
import { Route as BlogBlogImport } from './routes/blog/_blog'
import { Route as AuthAuthImport } from './routes/auth/_auth'
import { Route as AdminAdminImport } from './routes/admin/_admin'
import { Route as R533096132DC5DemoImport } from './routes/533096132DC5/demo'
import { Route as HomesHomesIndexImport } from './routes/homes/_homes/index'
import { Route as ContractorContractorIndexImport } from './routes/contractor/_contractor/index'
import { Route as BlogBlogIndexImport } from './routes/blog/_blog/index'
import { Route as AdminAdminIndexImport } from './routes/admin/_admin/index'
import { Route as HomesHomesAcceptInviteImport } from './routes/homes/_homes/accept-invite'
import { Route as BlogBlogBlogidImport } from './routes/blog/_blog/$blog_id'
import { Route as AuthAuthVerifyRequestImport } from './routes/auth/_auth/verify-request'
import { Route as AuthAuthSigninImport } from './routes/auth/_auth/signin'
import { Route as AuthAuthErrorImport } from './routes/auth/_auth/error'
import { Route as HomesHomesHomeidIndexImport } from './routes/homes/_homes/$home_id/index'
import { Route as WebviewHomeHomeidRecommendationsImport } from './routes/webview/home.$home_id.recommendations'
import { Route as ContractorContractorHomeHomeidImport } from './routes/contractor/_contractor/home.$home_id'
import { Route as AdminAdminAssessmentAssessmentidImport } from './routes/admin/_admin/assessment.$assessment_id'

// Create Virtual Routes

const HomesImport = createFileRoute('/homes')()
const ContractorImport = createFileRoute('/contractor')()
const BlogImport = createFileRoute('/blog')()
const AuthImport = createFileRoute('/auth')()
const AdminImport = createFileRoute('/admin')()

// Create/Update Routes

const HomesRoute = HomesImport.update({
  id: '/homes',
  path: '/homes',
  getParentRoute: () => rootRoute,
} as any)

const ContractorRoute = ContractorImport.update({
  id: '/contractor',
  path: '/contractor',
  getParentRoute: () => rootRoute,
} as any)

const BlogRoute = BlogImport.update({
  id: '/blog',
  path: '/blog',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const TermsRoute = TermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => rootRoute,
} as any)

const QuizRoute = QuizImport.update({
  id: '/quiz',
  path: '/quiz',
  getParentRoute: () => rootRoute,
} as any)

const PrivacyRoute = PrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any)

const AboutRoute = AboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WebviewAppRedirectRoute = WebviewAppRedirectImport.update({
  id: '/webview/app-redirect',
  path: '/webview/app-redirect',
  getParentRoute: () => rootRoute,
} as any)

const HomesHomesRoute = HomesHomesImport.update({
  id: '/_homes',
  getParentRoute: () => HomesRoute,
} as any)

const ContractorContractorRoute = ContractorContractorImport.update({
  id: '/_contractor',
  getParentRoute: () => ContractorRoute,
} as any)

const BlogBlogRoute = BlogBlogImport.update({
  id: '/_blog',
  getParentRoute: () => BlogRoute,
} as any)

const AuthAuthRoute = AuthAuthImport.update({
  id: '/_auth',
  getParentRoute: () => AuthRoute,
} as any)

const AdminAdminRoute = AdminAdminImport.update({
  id: '/_admin',
  getParentRoute: () => AdminRoute,
} as any)

const R533096132DC5DemoRoute = R533096132DC5DemoImport.update({
  id: '/533096132DC5/demo',
  path: '/533096132DC5/demo',
  getParentRoute: () => rootRoute,
} as any)

const HomesHomesIndexRoute = HomesHomesIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => HomesHomesRoute,
} as any)

const ContractorContractorIndexRoute = ContractorContractorIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ContractorContractorRoute,
} as any)

const BlogBlogIndexRoute = BlogBlogIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => BlogBlogRoute,
} as any)

const AdminAdminIndexRoute = AdminAdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AdminAdminRoute,
} as any)

const HomesHomesAcceptInviteRoute = HomesHomesAcceptInviteImport.update({
  id: '/accept-invite',
  path: '/accept-invite',
  getParentRoute: () => HomesHomesRoute,
} as any)

const BlogBlogBlogidRoute = BlogBlogBlogidImport.update({
  id: '/$blog_id',
  path: '/$blog_id',
  getParentRoute: () => BlogBlogRoute,
} as any)

const AuthAuthVerifyRequestRoute = AuthAuthVerifyRequestImport.update({
  id: '/verify-request',
  path: '/verify-request',
  getParentRoute: () => AuthAuthRoute,
} as any)

const AuthAuthSigninRoute = AuthAuthSigninImport.update({
  id: '/signin',
  path: '/signin',
  getParentRoute: () => AuthAuthRoute,
} as any)

const AuthAuthErrorRoute = AuthAuthErrorImport.update({
  id: '/error',
  path: '/error',
  getParentRoute: () => AuthAuthRoute,
} as any)

const HomesHomesHomeidIndexRoute = HomesHomesHomeidIndexImport.update({
  id: '/$home_id/',
  path: '/$home_id/',
  getParentRoute: () => HomesHomesRoute,
} as any)

const WebviewHomeHomeidRecommendationsRoute =
  WebviewHomeHomeidRecommendationsImport.update({
    id: '/webview/home/$home_id/recommendations',
    path: '/webview/home/$home_id/recommendations',
    getParentRoute: () => rootRoute,
  } as any)

const ContractorContractorHomeHomeidRoute =
  ContractorContractorHomeHomeidImport.update({
    id: '/home/$home_id',
    path: '/home/$home_id',
    getParentRoute: () => ContractorContractorRoute,
  } as any)

const AdminAdminAssessmentAssessmentidRoute =
  AdminAdminAssessmentAssessmentidImport.update({
    id: '/assessment/$assessment_id',
    path: '/assessment/$assessment_id',
    getParentRoute: () => AdminAdminRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutImport
      parentRoute: typeof rootRoute
    }
    '/privacy': {
      id: '/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyImport
      parentRoute: typeof rootRoute
    }
    '/quiz': {
      id: '/quiz'
      path: '/quiz'
      fullPath: '/quiz'
      preLoaderRoute: typeof QuizImport
      parentRoute: typeof rootRoute
    }
    '/terms': {
      id: '/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof TermsImport
      parentRoute: typeof rootRoute
    }
    '/533096132DC5/demo': {
      id: '/533096132DC5/demo'
      path: '/533096132DC5/demo'
      fullPath: '/533096132DC5/demo'
      preLoaderRoute: typeof R533096132DC5DemoImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminImport
      parentRoute: typeof rootRoute
    }
    '/admin/_admin': {
      id: '/admin/_admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminAdminImport
      parentRoute: typeof AdminRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/auth/_auth': {
      id: '/auth/_auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthAuthImport
      parentRoute: typeof AuthRoute
    }
    '/blog': {
      id: '/blog'
      path: '/blog'
      fullPath: '/blog'
      preLoaderRoute: typeof BlogImport
      parentRoute: typeof rootRoute
    }
    '/blog/_blog': {
      id: '/blog/_blog'
      path: '/blog'
      fullPath: '/blog'
      preLoaderRoute: typeof BlogBlogImport
      parentRoute: typeof BlogRoute
    }
    '/contractor': {
      id: '/contractor'
      path: '/contractor'
      fullPath: '/contractor'
      preLoaderRoute: typeof ContractorImport
      parentRoute: typeof rootRoute
    }
    '/contractor/_contractor': {
      id: '/contractor/_contractor'
      path: '/contractor'
      fullPath: '/contractor'
      preLoaderRoute: typeof ContractorContractorImport
      parentRoute: typeof ContractorRoute
    }
    '/homes': {
      id: '/homes'
      path: '/homes'
      fullPath: '/homes'
      preLoaderRoute: typeof HomesImport
      parentRoute: typeof rootRoute
    }
    '/homes/_homes': {
      id: '/homes/_homes'
      path: '/homes'
      fullPath: '/homes'
      preLoaderRoute: typeof HomesHomesImport
      parentRoute: typeof HomesRoute
    }
    '/webview/app-redirect': {
      id: '/webview/app-redirect'
      path: '/webview/app-redirect'
      fullPath: '/webview/app-redirect'
      preLoaderRoute: typeof WebviewAppRedirectImport
      parentRoute: typeof rootRoute
    }
    '/auth/_auth/error': {
      id: '/auth/_auth/error'
      path: '/error'
      fullPath: '/auth/error'
      preLoaderRoute: typeof AuthAuthErrorImport
      parentRoute: typeof AuthAuthImport
    }
    '/auth/_auth/signin': {
      id: '/auth/_auth/signin'
      path: '/signin'
      fullPath: '/auth/signin'
      preLoaderRoute: typeof AuthAuthSigninImport
      parentRoute: typeof AuthAuthImport
    }
    '/auth/_auth/verify-request': {
      id: '/auth/_auth/verify-request'
      path: '/verify-request'
      fullPath: '/auth/verify-request'
      preLoaderRoute: typeof AuthAuthVerifyRequestImport
      parentRoute: typeof AuthAuthImport
    }
    '/blog/_blog/$blog_id': {
      id: '/blog/_blog/$blog_id'
      path: '/$blog_id'
      fullPath: '/blog/$blog_id'
      preLoaderRoute: typeof BlogBlogBlogidImport
      parentRoute: typeof BlogBlogImport
    }
    '/homes/_homes/accept-invite': {
      id: '/homes/_homes/accept-invite'
      path: '/accept-invite'
      fullPath: '/homes/accept-invite'
      preLoaderRoute: typeof HomesHomesAcceptInviteImport
      parentRoute: typeof HomesHomesImport
    }
    '/admin/_admin/': {
      id: '/admin/_admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AdminAdminIndexImport
      parentRoute: typeof AdminAdminImport
    }
    '/blog/_blog/': {
      id: '/blog/_blog/'
      path: '/'
      fullPath: '/blog/'
      preLoaderRoute: typeof BlogBlogIndexImport
      parentRoute: typeof BlogBlogImport
    }
    '/contractor/_contractor/': {
      id: '/contractor/_contractor/'
      path: '/'
      fullPath: '/contractor/'
      preLoaderRoute: typeof ContractorContractorIndexImport
      parentRoute: typeof ContractorContractorImport
    }
    '/homes/_homes/': {
      id: '/homes/_homes/'
      path: '/'
      fullPath: '/homes/'
      preLoaderRoute: typeof HomesHomesIndexImport
      parentRoute: typeof HomesHomesImport
    }
    '/admin/_admin/assessment/$assessment_id': {
      id: '/admin/_admin/assessment/$assessment_id'
      path: '/assessment/$assessment_id'
      fullPath: '/admin/assessment/$assessment_id'
      preLoaderRoute: typeof AdminAdminAssessmentAssessmentidImport
      parentRoute: typeof AdminAdminImport
    }
    '/contractor/_contractor/home/$home_id': {
      id: '/contractor/_contractor/home/$home_id'
      path: '/home/$home_id'
      fullPath: '/contractor/home/$home_id'
      preLoaderRoute: typeof ContractorContractorHomeHomeidImport
      parentRoute: typeof ContractorContractorImport
    }
    '/webview/home/$home_id/recommendations': {
      id: '/webview/home/$home_id/recommendations'
      path: '/webview/home/$home_id/recommendations'
      fullPath: '/webview/home/$home_id/recommendations'
      preLoaderRoute: typeof WebviewHomeHomeidRecommendationsImport
      parentRoute: typeof rootRoute
    }
    '/homes/_homes/$home_id/': {
      id: '/homes/_homes/$home_id/'
      path: '/$home_id'
      fullPath: '/homes/$home_id'
      preLoaderRoute: typeof HomesHomesHomeidIndexImport
      parentRoute: typeof HomesHomesImport
    }
  }
}

// Create and export the route tree

interface AdminAdminRouteChildren {
  AdminAdminIndexRoute: typeof AdminAdminIndexRoute
  AdminAdminAssessmentAssessmentidRoute: typeof AdminAdminAssessmentAssessmentidRoute
}

const AdminAdminRouteChildren: AdminAdminRouteChildren = {
  AdminAdminIndexRoute: AdminAdminIndexRoute,
  AdminAdminAssessmentAssessmentidRoute: AdminAdminAssessmentAssessmentidRoute,
}

const AdminAdminRouteWithChildren = AdminAdminRoute._addFileChildren(
  AdminAdminRouteChildren,
)

interface AdminRouteChildren {
  AdminAdminRoute: typeof AdminAdminRouteWithChildren
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminAdminRoute: AdminAdminRouteWithChildren,
}

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren)

interface AuthAuthRouteChildren {
  AuthAuthErrorRoute: typeof AuthAuthErrorRoute
  AuthAuthSigninRoute: typeof AuthAuthSigninRoute
  AuthAuthVerifyRequestRoute: typeof AuthAuthVerifyRequestRoute
}

const AuthAuthRouteChildren: AuthAuthRouteChildren = {
  AuthAuthErrorRoute: AuthAuthErrorRoute,
  AuthAuthSigninRoute: AuthAuthSigninRoute,
  AuthAuthVerifyRequestRoute: AuthAuthVerifyRequestRoute,
}

const AuthAuthRouteWithChildren = AuthAuthRoute._addFileChildren(
  AuthAuthRouteChildren,
)

interface AuthRouteChildren {
  AuthAuthRoute: typeof AuthAuthRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAuthRoute: AuthAuthRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface BlogBlogRouteChildren {
  BlogBlogBlogidRoute: typeof BlogBlogBlogidRoute
  BlogBlogIndexRoute: typeof BlogBlogIndexRoute
}

const BlogBlogRouteChildren: BlogBlogRouteChildren = {
  BlogBlogBlogidRoute: BlogBlogBlogidRoute,
  BlogBlogIndexRoute: BlogBlogIndexRoute,
}

const BlogBlogRouteWithChildren = BlogBlogRoute._addFileChildren(
  BlogBlogRouteChildren,
)

interface BlogRouteChildren {
  BlogBlogRoute: typeof BlogBlogRouteWithChildren
}

const BlogRouteChildren: BlogRouteChildren = {
  BlogBlogRoute: BlogBlogRouteWithChildren,
}

const BlogRouteWithChildren = BlogRoute._addFileChildren(BlogRouteChildren)

interface ContractorContractorRouteChildren {
  ContractorContractorIndexRoute: typeof ContractorContractorIndexRoute
  ContractorContractorHomeHomeidRoute: typeof ContractorContractorHomeHomeidRoute
}

const ContractorContractorRouteChildren: ContractorContractorRouteChildren = {
  ContractorContractorIndexRoute: ContractorContractorIndexRoute,
  ContractorContractorHomeHomeidRoute: ContractorContractorHomeHomeidRoute,
}

const ContractorContractorRouteWithChildren =
  ContractorContractorRoute._addFileChildren(ContractorContractorRouteChildren)

interface ContractorRouteChildren {
  ContractorContractorRoute: typeof ContractorContractorRouteWithChildren
}

const ContractorRouteChildren: ContractorRouteChildren = {
  ContractorContractorRoute: ContractorContractorRouteWithChildren,
}

const ContractorRouteWithChildren = ContractorRoute._addFileChildren(
  ContractorRouteChildren,
)

interface HomesHomesRouteChildren {
  HomesHomesAcceptInviteRoute: typeof HomesHomesAcceptInviteRoute
  HomesHomesIndexRoute: typeof HomesHomesIndexRoute
  HomesHomesHomeidIndexRoute: typeof HomesHomesHomeidIndexRoute
}

const HomesHomesRouteChildren: HomesHomesRouteChildren = {
  HomesHomesAcceptInviteRoute: HomesHomesAcceptInviteRoute,
  HomesHomesIndexRoute: HomesHomesIndexRoute,
  HomesHomesHomeidIndexRoute: HomesHomesHomeidIndexRoute,
}

const HomesHomesRouteWithChildren = HomesHomesRoute._addFileChildren(
  HomesHomesRouteChildren,
)

interface HomesRouteChildren {
  HomesHomesRoute: typeof HomesHomesRouteWithChildren
}

const HomesRouteChildren: HomesRouteChildren = {
  HomesHomesRoute: HomesHomesRouteWithChildren,
}

const HomesRouteWithChildren = HomesRoute._addFileChildren(HomesRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/privacy': typeof PrivacyRoute
  '/quiz': typeof QuizRoute
  '/terms': typeof TermsRoute
  '/533096132DC5/demo': typeof R533096132DC5DemoRoute
  '/admin': typeof AdminAdminRouteWithChildren
  '/auth': typeof AuthAuthRouteWithChildren
  '/blog': typeof BlogBlogRouteWithChildren
  '/contractor': typeof ContractorContractorRouteWithChildren
  '/homes': typeof HomesHomesRouteWithChildren
  '/webview/app-redirect': typeof WebviewAppRedirectRoute
  '/auth/error': typeof AuthAuthErrorRoute
  '/auth/signin': typeof AuthAuthSigninRoute
  '/auth/verify-request': typeof AuthAuthVerifyRequestRoute
  '/blog/$blog_id': typeof BlogBlogBlogidRoute
  '/homes/accept-invite': typeof HomesHomesAcceptInviteRoute
  '/admin/': typeof AdminAdminIndexRoute
  '/blog/': typeof BlogBlogIndexRoute
  '/contractor/': typeof ContractorContractorIndexRoute
  '/homes/': typeof HomesHomesIndexRoute
  '/admin/assessment/$assessment_id': typeof AdminAdminAssessmentAssessmentidRoute
  '/contractor/home/$home_id': typeof ContractorContractorHomeHomeidRoute
  '/webview/home/$home_id/recommendations': typeof WebviewHomeHomeidRecommendationsRoute
  '/homes/$home_id': typeof HomesHomesHomeidIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/privacy': typeof PrivacyRoute
  '/quiz': typeof QuizRoute
  '/terms': typeof TermsRoute
  '/533096132DC5/demo': typeof R533096132DC5DemoRoute
  '/admin': typeof AdminAdminIndexRoute
  '/auth': typeof AuthAuthRouteWithChildren
  '/blog': typeof BlogBlogIndexRoute
  '/contractor': typeof ContractorContractorIndexRoute
  '/homes': typeof HomesHomesIndexRoute
  '/webview/app-redirect': typeof WebviewAppRedirectRoute
  '/auth/error': typeof AuthAuthErrorRoute
  '/auth/signin': typeof AuthAuthSigninRoute
  '/auth/verify-request': typeof AuthAuthVerifyRequestRoute
  '/blog/$blog_id': typeof BlogBlogBlogidRoute
  '/homes/accept-invite': typeof HomesHomesAcceptInviteRoute
  '/admin/assessment/$assessment_id': typeof AdminAdminAssessmentAssessmentidRoute
  '/contractor/home/$home_id': typeof ContractorContractorHomeHomeidRoute
  '/webview/home/$home_id/recommendations': typeof WebviewHomeHomeidRecommendationsRoute
  '/homes/$home_id': typeof HomesHomesHomeidIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/privacy': typeof PrivacyRoute
  '/quiz': typeof QuizRoute
  '/terms': typeof TermsRoute
  '/533096132DC5/demo': typeof R533096132DC5DemoRoute
  '/admin': typeof AdminRouteWithChildren
  '/admin/_admin': typeof AdminAdminRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/auth/_auth': typeof AuthAuthRouteWithChildren
  '/blog': typeof BlogRouteWithChildren
  '/blog/_blog': typeof BlogBlogRouteWithChildren
  '/contractor': typeof ContractorRouteWithChildren
  '/contractor/_contractor': typeof ContractorContractorRouteWithChildren
  '/homes': typeof HomesRouteWithChildren
  '/homes/_homes': typeof HomesHomesRouteWithChildren
  '/webview/app-redirect': typeof WebviewAppRedirectRoute
  '/auth/_auth/error': typeof AuthAuthErrorRoute
  '/auth/_auth/signin': typeof AuthAuthSigninRoute
  '/auth/_auth/verify-request': typeof AuthAuthVerifyRequestRoute
  '/blog/_blog/$blog_id': typeof BlogBlogBlogidRoute
  '/homes/_homes/accept-invite': typeof HomesHomesAcceptInviteRoute
  '/admin/_admin/': typeof AdminAdminIndexRoute
  '/blog/_blog/': typeof BlogBlogIndexRoute
  '/contractor/_contractor/': typeof ContractorContractorIndexRoute
  '/homes/_homes/': typeof HomesHomesIndexRoute
  '/admin/_admin/assessment/$assessment_id': typeof AdminAdminAssessmentAssessmentidRoute
  '/contractor/_contractor/home/$home_id': typeof ContractorContractorHomeHomeidRoute
  '/webview/home/$home_id/recommendations': typeof WebviewHomeHomeidRecommendationsRoute
  '/homes/_homes/$home_id/': typeof HomesHomesHomeidIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/about'
    | '/privacy'
    | '/quiz'
    | '/terms'
    | '/533096132DC5/demo'
    | '/admin'
    | '/auth'
    | '/blog'
    | '/contractor'
    | '/homes'
    | '/webview/app-redirect'
    | '/auth/error'
    | '/auth/signin'
    | '/auth/verify-request'
    | '/blog/$blog_id'
    | '/homes/accept-invite'
    | '/admin/'
    | '/blog/'
    | '/contractor/'
    | '/homes/'
    | '/admin/assessment/$assessment_id'
    | '/contractor/home/$home_id'
    | '/webview/home/$home_id/recommendations'
    | '/homes/$home_id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/about'
    | '/privacy'
    | '/quiz'
    | '/terms'
    | '/533096132DC5/demo'
    | '/admin'
    | '/auth'
    | '/blog'
    | '/contractor'
    | '/homes'
    | '/webview/app-redirect'
    | '/auth/error'
    | '/auth/signin'
    | '/auth/verify-request'
    | '/blog/$blog_id'
    | '/homes/accept-invite'
    | '/admin/assessment/$assessment_id'
    | '/contractor/home/$home_id'
    | '/webview/home/$home_id/recommendations'
    | '/homes/$home_id'
  id:
    | '__root__'
    | '/'
    | '/about'
    | '/privacy'
    | '/quiz'
    | '/terms'
    | '/533096132DC5/demo'
    | '/admin'
    | '/admin/_admin'
    | '/auth'
    | '/auth/_auth'
    | '/blog'
    | '/blog/_blog'
    | '/contractor'
    | '/contractor/_contractor'
    | '/homes'
    | '/homes/_homes'
    | '/webview/app-redirect'
    | '/auth/_auth/error'
    | '/auth/_auth/signin'
    | '/auth/_auth/verify-request'
    | '/blog/_blog/$blog_id'
    | '/homes/_homes/accept-invite'
    | '/admin/_admin/'
    | '/blog/_blog/'
    | '/contractor/_contractor/'
    | '/homes/_homes/'
    | '/admin/_admin/assessment/$assessment_id'
    | '/contractor/_contractor/home/$home_id'
    | '/webview/home/$home_id/recommendations'
    | '/homes/_homes/$home_id/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AboutRoute: typeof AboutRoute
  PrivacyRoute: typeof PrivacyRoute
  QuizRoute: typeof QuizRoute
  TermsRoute: typeof TermsRoute
  R533096132DC5DemoRoute: typeof R533096132DC5DemoRoute
  AdminRoute: typeof AdminRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
  BlogRoute: typeof BlogRouteWithChildren
  ContractorRoute: typeof ContractorRouteWithChildren
  HomesRoute: typeof HomesRouteWithChildren
  WebviewAppRedirectRoute: typeof WebviewAppRedirectRoute
  WebviewHomeHomeidRecommendationsRoute: typeof WebviewHomeHomeidRecommendationsRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AboutRoute: AboutRoute,
  PrivacyRoute: PrivacyRoute,
  QuizRoute: QuizRoute,
  TermsRoute: TermsRoute,
  R533096132DC5DemoRoute: R533096132DC5DemoRoute,
  AdminRoute: AdminRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
  BlogRoute: BlogRouteWithChildren,
  ContractorRoute: ContractorRouteWithChildren,
  HomesRoute: HomesRouteWithChildren,
  WebviewAppRedirectRoute: WebviewAppRedirectRoute,
  WebviewHomeHomeidRecommendationsRoute: WebviewHomeHomeidRecommendationsRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/about",
        "/privacy",
        "/quiz",
        "/terms",
        "/533096132DC5/demo",
        "/admin",
        "/auth",
        "/blog",
        "/contractor",
        "/homes",
        "/webview/app-redirect",
        "/webview/home/$home_id/recommendations"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/about": {
      "filePath": "about.tsx"
    },
    "/privacy": {
      "filePath": "privacy.tsx"
    },
    "/quiz": {
      "filePath": "quiz.tsx"
    },
    "/terms": {
      "filePath": "terms.tsx"
    },
    "/533096132DC5/demo": {
      "filePath": "533096132DC5/demo.tsx"
    },
    "/admin": {
      "filePath": "admin",
      "children": [
        "/admin/_admin"
      ]
    },
    "/admin/_admin": {
      "filePath": "admin/_admin.tsx",
      "parent": "/admin",
      "children": [
        "/admin/_admin/",
        "/admin/_admin/assessment/$assessment_id"
      ]
    },
    "/auth": {
      "filePath": "auth",
      "children": [
        "/auth/_auth"
      ]
    },
    "/auth/_auth": {
      "filePath": "auth/_auth.tsx",
      "parent": "/auth",
      "children": [
        "/auth/_auth/error",
        "/auth/_auth/signin",
        "/auth/_auth/verify-request"
      ]
    },
    "/blog": {
      "filePath": "blog",
      "children": [
        "/blog/_blog"
      ]
    },
    "/blog/_blog": {
      "filePath": "blog/_blog.tsx",
      "parent": "/blog",
      "children": [
        "/blog/_blog/$blog_id",
        "/blog/_blog/"
      ]
    },
    "/contractor": {
      "filePath": "contractor",
      "children": [
        "/contractor/_contractor"
      ]
    },
    "/contractor/_contractor": {
      "filePath": "contractor/_contractor.tsx",
      "parent": "/contractor",
      "children": [
        "/contractor/_contractor/",
        "/contractor/_contractor/home/$home_id"
      ]
    },
    "/homes": {
      "filePath": "homes",
      "children": [
        "/homes/_homes"
      ]
    },
    "/homes/_homes": {
      "filePath": "homes/_homes.tsx",
      "parent": "/homes",
      "children": [
        "/homes/_homes/accept-invite",
        "/homes/_homes/",
        "/homes/_homes/$home_id/"
      ]
    },
    "/webview/app-redirect": {
      "filePath": "webview/app-redirect.tsx"
    },
    "/auth/_auth/error": {
      "filePath": "auth/_auth/error.tsx",
      "parent": "/auth/_auth"
    },
    "/auth/_auth/signin": {
      "filePath": "auth/_auth/signin.tsx",
      "parent": "/auth/_auth"
    },
    "/auth/_auth/verify-request": {
      "filePath": "auth/_auth/verify-request.tsx",
      "parent": "/auth/_auth"
    },
    "/blog/_blog/$blog_id": {
      "filePath": "blog/_blog/$blog_id.tsx",
      "parent": "/blog/_blog"
    },
    "/homes/_homes/accept-invite": {
      "filePath": "homes/_homes/accept-invite.tsx",
      "parent": "/homes/_homes"
    },
    "/admin/_admin/": {
      "filePath": "admin/_admin/index.tsx",
      "parent": "/admin/_admin"
    },
    "/blog/_blog/": {
      "filePath": "blog/_blog/index.tsx",
      "parent": "/blog/_blog"
    },
    "/contractor/_contractor/": {
      "filePath": "contractor/_contractor/index.tsx",
      "parent": "/contractor/_contractor"
    },
    "/homes/_homes/": {
      "filePath": "homes/_homes/index.tsx",
      "parent": "/homes/_homes"
    },
    "/admin/_admin/assessment/$assessment_id": {
      "filePath": "admin/_admin/assessment.$assessment_id.tsx",
      "parent": "/admin/_admin"
    },
    "/contractor/_contractor/home/$home_id": {
      "filePath": "contractor/_contractor/home.$home_id.tsx",
      "parent": "/contractor/_contractor"
    },
    "/webview/home/$home_id/recommendations": {
      "filePath": "webview/home.$home_id.recommendations.tsx"
    },
    "/homes/_homes/$home_id/": {
      "filePath": "homes/_homes/$home_id/index.tsx",
      "parent": "/homes/_homes"
    }
  }
}
ROUTE_MANIFEST_END */
